<template>
  <v-dialog v-model="dialog" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-card-title class="purple white--text">
        <span class="headline">Configurações</span>
        <v-spacer />
        <v-btn icon class="hidden-sm-and-up" color="white" @click="cancel()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-card-text>
          <v-text-field
            outlined
            v-model="project.name"
            label="Nome do Projeto"
            hint="Exemplo: Meu Projeto"
            disabled
          />

          <v-text-field
            outlined
            v-model="project.unix"
            label="Nome Unix"
            disabled
          />

          <v-switch
            v-for="s in support"
            :key="s.unix"
            inset
            v-model="s.control.active"
            :loading="s.control.loading ? (s.control.active ? 'success' : 'error') : false"
            :disabled="s.control.disable"
            class="py-0 my-0">
            <template v-slot:label @click.stop>
              <v-badge
                v-if="s.control.loading"
                bordered
                :color="s.control.active ? 'success' : 'error'"
                :icon="s.control.active ? 'file_upload' : 'file_download'"
                inline
              >
                {{ s.info }}
              </v-badge>
              <span v-else>{{ s.info }}</span>
              <v-spacer />
              <v-chip color="grey" outlined label small style="font-family: monospace;">{{ s.unix }}</v-chip>
            </template>
          </v-switch>
        </v-card-text>

        <v-alert type="error" icon="warning" :value="error" transition="scale-transition" class="mx-4">
          {{ message }}
        </v-alert>
      </v-card-text>
      <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
        <v-btn
          color="error"
          text
          @click="cancel()"
        >
          Cancelar
        </v-btn>

        <v-spacer />

        <v-btn
          color="success white--text"
          depressed
          x-large
          @click="save()"
          :loading="saving"
        >
          Salvar
          <v-icon class="ml-1">
            done
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-row wrap>
          <v-col cols="12">
            <v-btn
              color="success white--text"
              depressed
              x-large
              @click="save()"
              block
              :loading="saving"
            >
              Salvar
              <v-icon class="ml-1">
                done
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

import ErrorHelper from '@/helpers/error'

export default {
  mixins: [
    ErrorHelper
  ],
  data: () => ({
    dialog: false,
    project: {
      name: '',
      unix: ''
    },
    support: [
      { unix: 'web', info: 'Site de informações públicas do projeto.' },
      { unix: 'api', info: 'Documentação pública da API.' },
      { unix: 'doc', info: 'Documentação técnica (não-pública).' },
      { unix: 'art', info: 'Logo e artefatos visuais.' },
      { unix: 'bin', info: 'Binários (APKs, APPXs, etc).' },
      { unix: 'twa', info: 'Encapsulamento de PWA para a Google Play.' }
    ],
    saving: false,
    headers: {},
    error: false,
    message: ''
  }),
  beforeMount () {
    this.reload()
  },
  mounted () {
    if (this.$localStorage.get('user').authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  methods: {
    open (project) {
      this.reload()

      this.project.name = project.name
      this.project.unix = project.unix

      axios.get(process.env.VUE_APP_API + '/support', { headers: this.headers }).then(response => {
        const support = response.data[this.project.unix]

        this.support.forEach(s => {
          switch (support[s.unix]) {
            case 'UNEXISTS':
            case 'DEACTIVATED':
              s.control.active = false
              s.control.disable = false
              s.control.loading = false

              break

            case 'CREATING':
            case 'ACTIVATING':
              s.control.active = true
              s.control.disable = true
              s.control.loading = true

              break

            case 'ACTIVE':
              s.control.active = true
              s.control.disable = false
              s.control.loading = false

              break

            case 'DEACTIVATING':
              s.control.active = false
              s.control.disable = true
              s.control.loading = true

              break
          }

          this.dialog = true
        })
      }).catch(error => {
        this.error.message = this.errorMessage(error)

        this.error.active = true

        this.dialog = true
      })
    },
    reload () {
      this.saving = false

      this.project.name = ''
      this.project.unix = ''

      this.support.forEach(s => {
        s.control = {
          active: false,
          loading: true,
          disable: true
        }
      })
    },
    cancel () {
      this.reload()

      this.dialog = false
    },
    save () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      const err = error => {
        this.saving = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.saving = true

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        const data = { unix: this.project.unix }

        this.support.forEach(s => {
          data[s.unix] = s.control.active
        })

        axios.post(api + '/support', data, { headers: this.headers }).then(response => {
          this.saving = false

          this.$emit('message', 'Repositórios de apoio do projeto "' + this.project.name + '" alterados com sucesso!', 'success')

          this.$emit('refresh')

          this.reload()

          this.dialog = false
        }).catch(err)
      }).catch(err)
    }
  }
}
</script>
