<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" scrollable max-width="1000px">
      <v-card>
        <v-toolbar color="cyan" dark flat>
          <v-toolbar-title>Inspeção dos Comandos no Servidor Remoto</v-toolbar-title>

          <v-spacer />

          <v-chip v-if="environment" label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="white--text">{{ app.repository.split('/').join(' / ') }} @ {{ env }}</v-chip>

          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-chip label class="ml-4 mb-0 mt-2 mr-2" color="rgb(255,255,255,0.2)">Docker Compose:</v-chip>
              <v-tab>Validação</v-tab>
              <v-tab>Deploy</v-tab>
              <v-tab>Backup</v-tab>
              <!--
              <v-chip label class="ml-4 mb-0 mt-2 mr-2" color="rgb(255,255,255,0.2)">PWAs:</v-chip>
              <v-tab>Build</v-tab>
              -->
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">

          <!-- Container Validation -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>Na validação de <i>containers</i> será executado o seguinte comando:</p>
                <p style="font-family: monospace;" class="font-weight-bold">env $(.env.io) docker-compose config</p>
                <p>
                  O arquivo <span style="font-family: monospace;" class="font-weight-bold">.env.io</span> é injetado por linha de comando.
                  Portanto, estas variáveis de ambiente tem precedência sobre todas as demais:
                </p>
                <v-textarea
                  :value="files.env_ci"
                  outlined
                  label=".env.io"
                  readonly
                  style="font-family: monospace;"
                />
                <v-alert type="info"><strong>Atenção!</strong> Alguns valores, tal como o autor do <i>deploy</i> ('<strong>IO_DEPLOYER</strong>') e a versão do <i>release</i> ('<strong>IO_VERSION</strong>') serão definidas apenas em tempo de <i>build</i>. Portanto os valores mostrados aqui são apenas exemplos.</v-alert>
                <p>Um arquivo <span style="font-family: monospace;" class="font-weight-bold">.env</span> será criado na raiz da aplicação. Por padrão, ele será automaticamente carregado pelo comando acima, onde:</p>
                <v-textarea
                  :value="files.env"
                  outlined
                  label=".env"
                  readonly
                  style="font-family: monospace;"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Container Deploy -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>No <i>deploy</i> de <i>containers</i> será executado o seguinte comando:</p>
                <p style="font-family: monospace;" class="font-weight-bold">env $(cat .env.io) docker-compose up --force-recreate --build</p>
                <p>
                  O arquivo <span style="font-family: monospace;" class="font-weight-bold">.env.io</span> é injetado por linha de comando.
                  Portanto, estas variáveis de ambiente tem precedência sobre todas as demais:
                </p>
                <v-textarea
                  :value="files.env_ci"
                  outlined
                  label=".env.io"
                  readonly
                  style="font-family: monospace;"
                />
                <v-alert type="info"><strong>Atenção!</strong> Alguns valores, tal como o autor do <i>deploy</i> ('<strong>IO_DEPLOYER</strong>') e a versão do <i>release</i> ('<strong>IO_VERSION</strong>') serão definidas apenas em tempo de <i>build</i>. Portanto os valores mostrados aqui são apenas exemplos.</v-alert>
                <p>Um arquivo <span style="font-family: monospace;" class="font-weight-bold">.env</span> será criado na raiz da aplicação. Por padrão, ele será automaticamente carregado pelo comando acima, onde:</p>
                <v-textarea
                  :value="files.env"
                  outlined
                  label=".env"
                  readonly
                  style="font-family: monospace;"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Container Backup -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>No <i>backup</i> de <i>containers</i> será executado o seguinte comando:</p>
                <p style="font-family: monospace;" class="font-weight-bold">env $(cat .env.sh) docker-compose run --rm --no-deps backup</p>
                <p>
                  O arquivo <span style="font-family: monospace;" class="font-weight-bold">.env.sh</span> é injetado por linha de comando.
                  Portanto, estas variáveis de ambiente tem precedência sobre todas as demais:
                </p>
                <v-textarea
                  :value="files.env_backup"
                  outlined
                  label=".env.sh"
                  readonly
                  style="font-family: monospace;"
                />
                <v-alert type="info"><strong>Atenção!</strong> Alguns valores, tal como o autor do <i>deploy</i> ('<strong>IO_DEPLOYER</strong>') e a versão do <i>release</i> ('<strong>IO_VERSION</strong>') serão definidas apenas em tempo de <i>build</i>. Portanto os valores mostrados aqui são apenas exemplos.</v-alert>
                <p>Um arquivo <span style="font-family: monospace;" class="font-weight-bold">.env</span> será criado na raiz da aplicação. Por padrão, ele será automaticamente carregado pelo comando acima, onde:</p>
                <v-textarea
                  :value="files.env"
                  outlined
                  label=".env"
                  readonly
                  style="font-family: monospace;"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- PWA Build -->
          <!--
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>No <i>build</i> de <i>PWAs</i> será executado o seguinte comando:</p>
                <p style="font-family: monospace;" class="font-weight-bold">env $(cat .env.io) npm run build</p>
                <p>
                  O arquivo <span style="font-family: monospace;" class="font-weight-bold">.env.io</span> é injetado por linha de comando.
                  Portanto, estas variáveis de ambiente tem precedência sobre todas as demais:
                </p>
                <v-textarea
                  :value="files.env_ci"
                  outlined
                  label=".env.io"
                  readonly
                  style="font-family: monospace;"
                />
                <v-alert type="info"><strong>Atenção!</strong> Alguns valores, tal como o autor do <i>deploy</i> ('<strong>IO_DEPLOYER</strong>') e a versão do <i>release</i> ('<strong>IO_VERSION</strong>') serão definidas apenas em tempo de <i>build</i>. Portanto os valores mostrados aqui são apenas exemplos.</v-alert>
                <p>Um arquivo <span style="font-family: monospace;" class="font-weight-bold">.env</span> será criado na raiz da aplicação. Por padrão, ele será automaticamente carregado pelo comando acima, onde:</p>
                <v-textarea
                  :value="files.env"
                  outlined
                  label=".env"
                  readonly
                  style="font-family: monospace;"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          -->
        </v-tabs-items>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      tab: null,
      environment: null,
      project: null,
      app: null,
      env: null,
      files: {
        env: '',
        env_ci: '',
        env_backup: ''
      }
    }
  },
  methods: {
    open (environment, project, app, env) {
      this.environment = environment
      this.project = project
      this.app = app
      this.env = env

      this.files.env = this.files.env_ci = this.files.env_backup = ''

      for (let i = 0; i < this.environment.variables.length; i++) {
        if (this.environment.variables[i].type === 'SERVER') {
          // eslint-disable-next-line quotes
          this.files.env_ci += this.environment.variables[i].name + '=' + environment.variables[i].value + "\n"

          // eslint-disable-next-line quotes
          this.files.env_backup += this.environment.variables[i].name + '=' + (environment.variables[i].name === 'COMPOSE_PROFILES' ? 'tools' : environment.variables[i].value) + "\n"
        } else {
          // eslint-disable-next-line quotes
          this.files.env += this.environment.variables[i].name + '=' + environment.variables[i].value + "\n"
        }
      }

      this.files.env = this.files.env.trim()
      this.files.env_ci = this.files.env_ci.trim()
      this.files.env_backup = this.files.env_backup.trim()

      this.dialog = true
    }
  }
}
</script>
