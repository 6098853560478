<template>
  <v-card outlined color="white" class="mt-6">
    <v-expansion-panels focusable>
      <v-expansion-panel v-for="(item,i) in ports" :key="i">
        <v-expansion-panel-header>
          <span style="font-family: monospace;" class="font-weight-bold">{{ item.name }}</span>
          {{ environment.cluster }}:{{ item.value }}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <!-- Subdomain -->
          <v-subheader class="pl-1 pr-0">
            <span>Subdomínio Auto-Gerado</span>
            <v-spacer />
            <v-btn icon color="info" @click="$refs['info'].open(null, 'Escolha um domínio que melhor reflita a finalidade do serviço disponibilizado nesta porta.\
              Por exemplo, um CMS em uma URL https://' + project.unix + '.manager.sede.embrapa.br faz bastante sentido, da mesma forma que uma RESTful API estaria\
              melhor disponibilizada em https://' + project.unix + '.api.sede.embrapa.br.\
              Para manter a URL final do serviço a menor possível, você pode optar por utilizar apenas o nome do projeto no subdomínio, entretanto a URL\
              final deve ser única.')">
              <v-icon>help</v-icon>
            </v-btn>
          </v-subheader>
          <v-row cols="12">
            <v-col cols="1">
              <v-switch v-model="switches.domain" :value="item.name" @change="cleanupDomain(item.name)" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="subdomínio"
                :prefix="'https://' + project.unix + ' -'"
                outlined
                single-line
                :disabled="!switches.domain.includes(item.name)"
                v-model="values.domain[item.name].subdomain"
                :rules="urlSubdomainRules(item.name)"
              />
            </v-col>
            <v-col cols="5">
              <v-select
                label="domínio"
                :items="subdomains"
                outlined
                prefix="."
                single-line
                required
                :disabled="!switches.domain.includes(item.name)"
                v-model="values.domain[item.name].domain"
                :rules="urlDomainRules(item.name)"
              />
            </v-col>
          </v-row>

          <!-- Alias -->
          <v-subheader class="pl-1 pr-0">
            <span>URL Externa (alias)</span>
            <v-spacer />
            <v-btn icon color="info" @click="$refs['info'].open(null, 'Você deverá criar um registro do\
              tipo CNAME no gerenciador de DNS do domínio externo apontando para \'router.embrapa.io\'.\
              Será utilizado um certificado SSL da Let\'s Encrypt no domínio escolhido.\
              Atenção! Certifique-se de configurar o DNS antes de realizar o deploy da build.')">
              <v-icon>help</v-icon>
            </v-btn>
          </v-subheader>
          <v-row cols="12">
            <v-col cols="1">
              <v-switch v-model="switches.alias" :value="item.name" @change="cleanupAlias(item.name)" />
            </v-col>
            <v-col cols="11">
              <v-text-field
                label="seu.domínio.com.br"
                prefix="https://"
                outlined
                single-line
                append-icon="public"
                :disabled="!switches.alias.includes(item.name)"
                v-model="values.alias[item.name].url"
                :rules="aliasRules(item.name)"
              />
            </v-col>
          </v-row>

          <!-- Subpath -->
          <v-subheader class="pl-1 pr-0">
            <span>Subpath Auto-Gerado</span>
            <v-spacer />
            <v-btn icon color="info" @click="$refs['info'].open(null, 'Escolha um domínio que melhor reflita a finalidade do serviço disponibilizado nesta porta.\
              Por exemplo, um CMS em uma URL https://manager.sede.embrapa.br/' + project.unix + ' faz bastante sentido, da mesma forma que uma RESTful API estaria\
              melhor disponibilizada em https://api.sede.embrapa.br/' + project.unix + '.\
              Para manter a URL final do serviço a menor possível, você pode optar por utilizar apenas o nome do projeto na subpasta, entretanto a URL\
              final (formada pelo domínio e a subpasta) deve ser única.')">
              <v-icon>help</v-icon>
            </v-btn>
          </v-subheader>
          <v-row cols="12">
            <v-col cols="1">
              <v-switch v-model="switches.url" :value="item.name" @change="cleanupUrl(item.name)" />
            </v-col>
            <v-col cols="6">
              <v-select
                label="domínio"
                :items="subdomains"
                outlined
                prefix="https://"
                single-line
                required
                :disabled="!switches.url.includes(item.name)"
                v-model="values.url[item.name].domain"
                :rules="urlDomainRules(item.name)"
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
                label="subpasta"
                :prefix="'/ ' + project.unix + ' -'"
                outlined
                single-line
                :disabled="!switches.url.includes(item.name)"
                v-model="values.url[item.name].path"
                :rules="urlPathRules(item.name)"
              />
            </v-col>
          </v-row>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <dialog-wrapper ref="info" />
  </v-card>
</template>
<script>
import DialogWrapper from '@/components/DialogDefault.vue'

import UtilHelper from '@/helpers/util'

export default {
  components: {
    DialogWrapper
  },
  mixins: [
    UtilHelper
  ],
  props: {
    environment: {
      type: Object,
      require: true
    },
    project: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    app: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    env: {
      type: String,
      require: true,
      default: ''
    },
    subdomains: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    step: Number
  },
  data: () => ({
    ports: [],
    switches: {
      url: [],
      alias: [],
      domain: []
    },
    values: {
      url: {},
      alias: {},
      domain: {}
    }
  }),
  mounted () {
    this.loadPorts()
  },
  watch: {
    step (next, previous) {
      if (next === 5) this.loadPorts()
    }
  },
  methods: {
    loadPorts () {
      this.ports = this.environment.variables.filter(i => i.type === 'PORT')

      this.ports.forEach(p => {
        let i, j, k, iUrl, iAlias, iDomain

        i = j = k = 0

        iUrl = iAlias = iDomain = null

        while (i < this.environment.urls.length && iUrl === null) if (this.environment.urls[i].port === p.value) iUrl = i; else i++

        while (j < this.environment.aliases.length && iAlias === null) if (this.environment.aliases[j].port === p.value) iAlias = j; else j++

        while (k < this.environment.domains.length && iDomain === null) if (this.environment.domains[k].port === p.value) iDomain = k; else k++

        if (Number.isInteger(iUrl)) {
          this.values.url[p.name] = this.environment.urls[iUrl]

          if (this.subdomains.includes(this.environment.urls[iUrl].domain)) this.switches.url.push(p.name)
          else {
            this.values.url[p.name].domain = ''
            this.values.url[p.name].path = ''
          }
        } else {
          const index = this.environment.urls.length

          this.environment.urls.push({
            port: p.value,
            domain: '',
            path: ''
          })

          this.values.url[p.name] = this.environment.urls[index]
        }

        if (Number.isInteger(iAlias)) {
          this.values.alias[p.name] = this.environment.aliases[iAlias]

          if (this.checkDomain(this.environment.aliases[iAlias].url)) this.switches.alias.push(p.name)
          else this.environment.aliases[iAlias].url = ''
        } else {
          const index = this.environment.aliases.length

          this.environment.aliases.push({
            port: p.value,
            url: ''
          })

          this.values.alias[p.name] = this.environment.aliases[index]
        }

        if (Number.isInteger(iDomain)) {
          this.values.domain[p.name] = this.environment.domains[iDomain]

          if (this.subdomains.includes(this.environment.domains[iDomain].domain)) this.switches.domain.push(p.name)
          else {
            this.values.domain[p.name].domain = ''
            this.values.domain[p.name].subdomain = ''
          }
        } else {
          const index = this.environment.domains.length

          this.environment.domains.push({
            port: p.value,
            domain: '',
            subdomain: ''
          })

          this.values.domain[p.name] = this.environment.domains[index]
        }
      })
    },
    urlDomainRules (name) {
      return [
        v => this.subdomains.filter(i => i === v).length > 0 || 'Subdomínio inválido!'
      ]
    },
    urlPathRules (name) {
      return [
        v => /^[a-z0-9-]*$/.test(v) || 'Utilize apenas letras minúsculas, números e hífen!',
        v => Object.values(this.values.url).filter(u => u.domain === this.values.url[name].domain && u.path === v).length === 1 || 'Este subpath já está em uso!'
      ]
    },
    urlSubdomainRules (name) {
      return [
        v => /^[a-z0-9-]*$/.test(v) || 'Utilize apenas letras minúsculas, números e hífen!',
        v => (this.project.unix + (v.length ? '-' + v : '') + '.' + this.values.domain[name].domain).length <= 64 || 'A combinação de "subdomínio + domínio" não pode ter mais de 64 caracteres!',
        v => Object.values(this.values.domain).filter(u => u.domain === this.values.domain[name].domain && u.subdomain === v).length === 1 || 'Este subdomínio já está em uso!'
      ]
    },
    aliasRules (name) {
      return [
        v => !!v || 'Não pode ser vazio!',
        v => v.length <= 64 || 'O domínio não pode ter mais de 64 caracteres!',
        v => this.checkDomain(v) || 'Este não é um domínio válido!',
        v => Object.values(this.values.alias).filter(a => a.url === v).length === 1 || 'Este domínio já está em uso!'
      ]
    },
    cleanupUrl (name) {
      if (!this.switches.url.includes(name)) {
        this.values.url[name].domain = this.values.url[name].path = ''
      }
    },
    cleanupAlias (name) {
      if (!this.switches.alias.includes(name)) {
        this.values.alias[name].url = ''
      }
    },
    cleanupDomain (name) {
      if (!this.switches.domain.includes(name)) {
        this.values.domain[name].domain = this.values.domain[name].subdomain = ''
      }
    }
  }
}
</script>
