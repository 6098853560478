<template>
  <v-dialog v-model="dialog" width="650px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card :tile="$vuetify.breakpoint.xsOnly">
      <v-toolbar dark class="deep-orange darken-2">
        <v-toolbar-title>Analytics</v-toolbar-title>
        <!-- <v-chip label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="white--text ml-2">{{ repository.split('/').join(' / ') }}</v-chip> -->
        <v-spacer />
        <v-text-field
          label="Matomo Site ID"
          :value="id"
          dark
          dense
          readonly
          hide-details
          hide-spin-buttons
          flat
          outlined
          style="font-family: monospace; font-weight: bold; width: 0px;"
          prepend-inner-icon="tag"
          append-icon="content_copy"
          ref="dsn"
          @click="copyDsn()"
          v-if="!$vuetify.breakpoint.xsOnly"
        />
        <v-btn icon class="hidden-sm-and-up ml-2" color="white" @click="cancel()">
          <v-icon>close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs
            v-model="tab"
            centered
            dark
            icons-and-text>

            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#summary">
              Sumário
              <v-icon>flag</v-icon>
            </v-tab>
            <v-tab href="#visits">
              Visitas
              <v-icon>leaderboard</v-icon>
            </v-tab>
            <v-tab href="#map">
              Localização
              <v-icon>travel_explore</v-icon>
            </v-tab>
            <v-tab href="#devices">
              Dispositivos
              <v-icon>devices</v-icon>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <template v-if="$vuetify.breakpoint.xsOnly">
        <v-card-text class="title mt-6">
          Matomo Site ID:
        </v-card-text>
        <v-card-text>
          <v-text-field
            :value="id"
            readonly
            hide-details
            hide-spin-buttons
            single-line
            flat
            outlined
            filled
            style="font-family: monospace; font-weight: bold; text-align: center;"
            prepend-inner-icon="tag"
            append-icon="content_copy"
            ref="dsn"
            @click="copyDsn()"
          />
        </v-card-text>
      </template>
      <v-tabs-items v-model="tab">
        <v-tab-item value="summary">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="6" sm="4" md="4" lg="4" xl="4" class="px-1 py-1" v-for="c in cards" :key="c['_id']">
                <v-card height="130" :color="c.color" dark>
                  <v-card-title primary-title>
                    <div class="headline">{{ summary[c.name] }}</div>
                    <v-spacer />
                    <v-btn icon dark x-small @click="$refs['info'].open(null, c.info)">
                      <v-icon>info</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-subtitle>{{ c.label }}</v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="visits" class="px-3 pt-2">
          <iframe
            :src="widgets.visits"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            width="100%"
            height="300px"
          />
        </v-tab-item>
        <v-tab-item value="map" class="px-3 pt-2">
          <iframe
            :src="widgets.map"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            width="100%"
            height="600px"
          />
        </v-tab-item>
        <v-tab-item value="devices" class="px-3 pt-2">
          <iframe
            :src="widgets.devices"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            width="100%"
            height="600px"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions v-if="$vuetify.breakpoint.xsOnly" class="pb-6">
        <v-row wrap>
          <v-col cols="12" class="py-1">
            <v-btn dark x-large color="pink" block :href="url" target="_blank">
              <v-icon class="mr-2">query_stats</v-icon>
              Relatório Completo
            </v-btn>
          </v-col>
          <v-col cols="12" class="py-1">
            <v-btn dark x-large color="blue-grey" block :href="help" target="_blank">
              <v-icon class="mr-2">auto_stories</v-icon>
              Ver Documentação
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions v-else>
        <v-row wrap>
          <v-col cols="6" class="pr-1">
            <v-btn dark x-large color="blue-grey" block :href="help" target="_blank">
              <v-icon class="mr-2">auto_stories</v-icon>
              Ver Documentação
            </v-btn>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn dark x-large color="pink" block :href="url" target="_blank">
              <v-icon class="mr-2">query_stats</v-icon>
              Relatório Completo
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar" timeout="2000" color="deep-orange darken-2" min-width="0px">Site ID copiado para a área de transferência!</v-snackbar>

    <dialog-wrapper ref="info" />

  </v-dialog>
</template>

<script>
import axios from 'axios'

import DialogWrapper from '@/components/DialogDefault.vue'

export default {
  components: {
    DialogWrapper
  },
  data: () => ({
    dialog: false,
    snackbar: false,
    tab: null,
    repository: '',
    id: 0,
    url: '',
    help: 'https://matomo.org/guides/',
    cards: [
      { name: 'visits', label: 'Visitações em todo o período', color: 'cyan darken-2', info: 'Se um visitante acessar seu site pela primeira vez ou visitar uma página mais de 30 minutos após a última visualização da página, isso será registrado como uma nova visita.' },
      { name: 'visits_evolution', label: 'Evolução das visitas no último mês', color: 'indigo darken-1', info: 'Evolução do número de visitas no último mês.' },
      { name: 'unique_visitors', label: 'Visitantes únicos em todo o período', color: 'teal darken-2', info: 'O número de visitantes não duplicados que chegam ao seu site. Cada usuário é contado apenas uma vez, mesmo que visite o site várias vezes ao dia.' },
      { name: 'load_time', label: 'Tempo médio de carregamento (em segundos)', color: 'red darken-1', info: 'Tempo médio (em segundos) que leva desde a solicitação de uma página até que ela seja totalmente renderizada no navegador.' },
      { name: 'pageviews', label: 'Visualizações de páginas em todo o período', color: 'deep-purple darken-1', info: 'Número de vezes que as páginas foram visitadas.' },
      { name: 'pageviews_evolution', label: 'Evolução das visualizações de página no último mês', color: 'purple darken-3', info: 'Evolução do número de páginas visitadas no último mês.' }
    ],
    summary: {},
    widgets: {
      visits: '',
      map: ''
    }
  }),
  beforeMount () {
    this.reload()
  },
  methods: {
    open (app) {
      this.reload()

      if (!app.hit) return

      this.repository = app.repository

      this.id = app.hit.id

      this.url = process.env.VUE_APP_MATOMO + '/index.php?idSite=' + this.id

      this.loadSummary()

      this.loadWidgets()

      this.dialog = true
    },
    reload () {
      this.tab = 'tab-1'

      this.repository = ''
      this.id = 0
      this.url = ''

      this.widgets = []

      this.cards.forEach(c => {
        this.summary[c.name] = '...'
      })
    },
    copyDsn () {
      const e = this.$refs.dsn.$el.querySelector('input')
      e.select()
      document.execCommand('copy')
      this.snackbar = true
    },
    cancel () {
      this.reload()

      this.dialog = false
    },
    loadSummary () {
      console.log(process.env.VUE_APP_MATOMO)

      const requests = [
        axios.get(process.env.VUE_APP_MATOMO, {
          params: {
            module: 'API',
            format: 'JSON',
            method: 'MultiSites.getOne',
            period: 'range',
            date: '2022-12-01,today',
            idSite: this.id
          }
        }),
        axios.get(process.env.VUE_APP_MATOMO, {
          params: {
            module: 'API',
            format: 'JSON',
            method: 'MultiSites.getOne',
            period: 'month',
            date: 'yesterday',
            idSite: this.id
          }
        }),
        axios.get(process.env.VUE_APP_MATOMO, {
          params: {
            module: 'API',
            format: 'JSON',
            method: 'VisitsSummary.getUniqueVisitors',
            period: 'range',
            date: '2022-12-01,today',
            idSite: this.id
          }
        }),
        axios.get(process.env.VUE_APP_MATOMO, {
          params: {
            module: 'API',
            format: 'JSON',
            method: 'PagePerformance.get',
            period: 'week',
            date: 'yesterday',
            idSite: this.id
          }
        })
      ]

      axios.all(requests).then(axios.spread((r1, r2, r3, r4) => {
        this.summary.visits = 'nb_visits' in r1.data ? r1.data.nb_visits : '0'
        this.summary.pageviews = 'nb_pageviews' in r1.data ? r1.data.nb_pageviews : '0'

        this.summary.visits_evolution = 'visits_evolution' in r2.data ? r2.data.visits_evolution : '0%'
        this.summary.pageviews_evolution = 'pageviews_evolution' in r2.data ? r2.data.pageviews_evolution : '0%'

        this.summary.unique_visitors = 'value' in r3.data ? r3.data.value : '0'

        this.summary.load_time = 'avg_page_load_time' in r4.data ? r4.data.avg_page_load_time : 'NaN'

        this.$forceUpdate()
      }))
    },
    loadWidgets () {
      this.widgets.visits = process.env.VUE_APP_MATOMO + '/index.php?forceView=1&viewDataTable=graphEvolution&module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=VisitsSummary&actionToWidgetize=getEvolutionGraph&idSite=' + this.id + '&period=day&date=yesterday'

      this.widgets.map = process.env.VUE_APP_MATOMO + '/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=UserCountryMap&actionToWidgetize=visitorMap&idSite=' + this.id + '&period=range&date=2022-12-01,today'

      this.widgets.devices = process.env.VUE_APP_MATOMO + '/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=DevicesDetection&actionToWidgetize=getType&idSite=' + this.id + '&period=range&date=2022-12-01,today'
    }
  }
}
</script>
