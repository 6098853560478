var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-6",attrs:{"outlined":"","color":"white"}},[_c('v-expansion-panels',{attrs:{"focusable":""}},_vm._l((_vm.ports),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-family":"monospace"}},[_vm._v(_vm._s(item.name))]),_vm._v(" "+_vm._s(_vm.environment.cluster)+":"+_vm._s(item.value)+"       ")]),_c('v-expansion-panel-content',[_c('v-subheader',{staticClass:"pl-1 pr-0"},[_c('span',[_vm._v("Subdomínio Auto-Gerado")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.$refs['info'].open(null, 'Escolha um domínio que melhor reflita a finalidade do serviço disponibilizado nesta porta.\
            Por exemplo, um CMS em uma URL https://' + _vm.project.unix + '.manager.sede.embrapa.br faz bastante sentido, da mesma forma que uma RESTful API estaria\
            melhor disponibilizada em https://' + _vm.project.unix + '.api.sede.embrapa.br.\
            Para manter a URL final do serviço a menor possível, você pode optar por utilizar apenas o nome do projeto no subdomínio, entretanto a URL\
            final deve ser única.')}}},[_c('v-icon',[_vm._v("help")])],1)],1),_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-switch',{attrs:{"value":item.name},on:{"change":function($event){return _vm.cleanupDomain(item.name)}},model:{value:(_vm.switches.domain),callback:function ($$v) {_vm.$set(_vm.switches, "domain", $$v)},expression:"switches.domain"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"subdomínio","prefix":'https://' + _vm.project.unix + ' -',"outlined":"","single-line":"","disabled":!_vm.switches.domain.includes(item.name),"rules":_vm.urlSubdomainRules(item.name)},model:{value:(_vm.values.domain[item.name].subdomain),callback:function ($$v) {_vm.$set(_vm.values.domain[item.name], "subdomain", $$v)},expression:"values.domain[item.name].subdomain"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"label":"domínio","items":_vm.subdomains,"outlined":"","prefix":".","single-line":"","required":"","disabled":!_vm.switches.domain.includes(item.name),"rules":_vm.urlDomainRules(item.name)},model:{value:(_vm.values.domain[item.name].domain),callback:function ($$v) {_vm.$set(_vm.values.domain[item.name], "domain", $$v)},expression:"values.domain[item.name].domain"}})],1)],1),_c('v-subheader',{staticClass:"pl-1 pr-0"},[_c('span',[_vm._v("URL Externa (alias)")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.$refs['info'].open(null, 'Você deverá criar um registro do\
            tipo CNAME no gerenciador de DNS do domínio externo apontando para \'router.embrapa.io\'.\
            Será utilizado um certificado SSL da Let\'s Encrypt no domínio escolhido.\
            Atenção! Certifique-se de configurar o DNS antes de realizar o deploy da build.')}}},[_c('v-icon',[_vm._v("help")])],1)],1),_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-switch',{attrs:{"value":item.name},on:{"change":function($event){return _vm.cleanupAlias(item.name)}},model:{value:(_vm.switches.alias),callback:function ($$v) {_vm.$set(_vm.switches, "alias", $$v)},expression:"switches.alias"}})],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"label":"seu.domínio.com.br","prefix":"https://","outlined":"","single-line":"","append-icon":"public","disabled":!_vm.switches.alias.includes(item.name),"rules":_vm.aliasRules(item.name)},model:{value:(_vm.values.alias[item.name].url),callback:function ($$v) {_vm.$set(_vm.values.alias[item.name], "url", $$v)},expression:"values.alias[item.name].url"}})],1)],1),_c('v-subheader',{staticClass:"pl-1 pr-0"},[_c('span',[_vm._v("Subpath Auto-Gerado")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){_vm.$refs['info'].open(null, 'Escolha um domínio que melhor reflita a finalidade do serviço disponibilizado nesta porta.\
            Por exemplo, um CMS em uma URL https://manager.sede.embrapa.br/' + _vm.project.unix + ' faz bastante sentido, da mesma forma que uma RESTful API estaria\
            melhor disponibilizada em https://api.sede.embrapa.br/' + _vm.project.unix + '.\
            Para manter a URL final do serviço a menor possível, você pode optar por utilizar apenas o nome do projeto na subpasta, entretanto a URL\
            final (formada pelo domínio e a subpasta) deve ser única.')}}},[_c('v-icon',[_vm._v("help")])],1)],1),_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-switch',{attrs:{"value":item.name},on:{"change":function($event){return _vm.cleanupUrl(item.name)}},model:{value:(_vm.switches.url),callback:function ($$v) {_vm.$set(_vm.switches, "url", $$v)},expression:"switches.url"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"domínio","items":_vm.subdomains,"outlined":"","prefix":"https://","single-line":"","required":"","disabled":!_vm.switches.url.includes(item.name),"rules":_vm.urlDomainRules(item.name)},model:{value:(_vm.values.url[item.name].domain),callback:function ($$v) {_vm.$set(_vm.values.url[item.name], "domain", $$v)},expression:"values.url[item.name].domain"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":"subpasta","prefix":'/ ' + _vm.project.unix + ' -',"outlined":"","single-line":"","disabled":!_vm.switches.url.includes(item.name),"rules":_vm.urlPathRules(item.name)},model:{value:(_vm.values.url[item.name].path),callback:function ($$v) {_vm.$set(_vm.values.url[item.name], "path", $$v)},expression:"values.url[item.name].path"}})],1)],1)],1)],1)}),1),_c('dialog-wrapper',{ref:"info"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }