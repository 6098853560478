export default {
  methods: {
    randomString (length) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

      let str = ''

      for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length))
      }

      return str
    },
    checkDomain (domain) {
      if (!domain) return false

      const re = /^[a-z0-9.-]+$/

      return re.test(domain)
    },
    checkUrl (link) {
      try {
        const url = new URL(link)
        return this.checkDomain(url.hostname)
      } catch (e) {
        return false
      }
    }
  }
}
