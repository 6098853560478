<template>
  <v-dialog v-model="dialog" width="650px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-card-title class="blue-grey white--text px-3">
        <span class="headline">Error Tracking</span>
        <v-spacer />
        <v-chip label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="white--text">{{ platform + (guide ? ' / ' + guide : '') }}</v-chip>
        <v-btn icon class="hidden-sm-and-up ml-2" color="white" @click="cancel()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="title mt-6">
        Data Source Name (DSN):
      </v-card-text>
      <v-card-text>
        <v-text-field
          :value="dsn"
          dense
          readonly
          hide-details
          hide-spin-buttons
          single-line
          flat
          outlined
          filled
          style="font-family: monospace; font-weight: bold; text-align: center;"
          append-icon="content_copy"
          ref="dsn"
          @click="copyDsn()"
        />
      </v-card-text>
      <v-card-actions v-if="$vuetify.breakpoint.xsOnly">
        <v-row wrap>
          <v-col cols="12" class="py-1">
            <v-btn dark x-large color="pink" block :href="url" target="_blank">
              <v-icon class="mr-2">dashboard</v-icon>
              Monitorar
            </v-btn>
          </v-col>
          <v-col cols="12" class="py-1">
            <v-btn dark x-large color="blue-grey" block :href="help" target="_blank">
              <v-icon class="mr-2">auto_stories</v-icon>
              Ver Documentação
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions v-else>
        <v-row wrap>
          <v-col cols="6" class="pr-1">
            <v-btn dark x-large color="blue-grey" block :href="help" target="_blank">
              <v-icon class="mr-2">auto_stories</v-icon>
              Ver Documentação
            </v-btn>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn dark x-large color="pink" block :href="url" target="_blank">
              <v-icon class="mr-2">dashboard</v-icon>
              Monitorar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" timeout="2000" color="info" min-width="0px">DSN copiado para a área de transferência!</v-snackbar>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    snackbar: false,
    platform: '',
    guide: null,
    dsn: '',
    url: '',
    help: 'https://docs.sentry.io/platforms/'
  }),
  beforeMount () {
    this.reload()
  },
  methods: {
    open (app) {
      this.reload()

      if (!app.bug) return

      this.platform = app.bug.platform

      this.dsn = 'https://' + app.bug.key + '@' + process.env.VUE_APP_SENTRY_HOST + '/' + app.bug.id

      this.url = 'https://' + process.env.VUE_APP_SENTRY_HOST + '/organizations/' + app.repository.split('/')[0] + '/projects/' + app.repository.split('/')[1] + '/'

      if (app.bug.platform && app.bug.platform.trim().length > 0) {
        if (app.bug.platform === 'react-native') this.platform = 'react-native'
        else {
          const aux = app.bug.platform.split('-')

          if (aux.length === 1) this.platform = aux[0]
          else {
            this.platform = aux[0]
            this.guide = aux[1]
          }
        }

        if (this.guide) this.help = 'https://docs.sentry.io/platforms/' + this.platform + '/guides/' + this.guide + '/'
        else this.help = 'https://docs.sentry.io/platforms/' + this.platform + '/'
      }

      this.dialog = true
    },
    reload () {
      this.platform = ''
      this.guide = null
      this.dsn = ''
      this.url = ''
      this.help = 'https://docs.sentry.io/platforms/'
    },
    copyDsn () {
      const e = this.$refs.dsn.$el.querySelector('input')
      e.select()
      document.execCommand('copy')
      this.snackbar = true
    },
    cancel () {
      this.reload()

      this.dialog = false
    }
  }
}
</script>
