<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" scrollable width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title v-if="title">
          {{ title }}
        </v-card-title>

        <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
        <v-card-text style="text-align: justify;" class="mt-6" v-html="message"></v-card-text>

        <v-divider v-if="confirmText || declineText" />

        <v-card-actions v-if="confirmText || declineText">
          <v-chip label color="teal" text-color="white" v-if="version">
            <v-avatar>
              <v-icon>update</v-icon>
            </v-avatar>
            Versão&nbsp;<b>{{ version }}</b>
          </v-chip>
          <v-spacer />
          <v-btn color="primary" text @click="dissent" v-if="declineText">
            {{ declineText }}
          </v-btn>
          <v-btn color="primary" text @click="consent" v-if="confirmText">
            {{ confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      title: null,
      confirmText: null,
      declineText: null,
      message: null,
      version: null
    }
  },
  methods: {
    open (title, message, confirmText, declineText, version) {
      this.dialog = true
      this.title = title
      this.message = message
      this.confirmText = confirmText
      this.declineText = declineText
      this.version = version
    },
    consent () {
      this.dialog = false
      this.$emit('consent')
    },
    dissent () {
      this.dialog = false
      this.$emit('dissent')
    }
  }
}
</script>
