<template>
  <v-card outlined color="white">
    <v-data-table
      :headers="headers"
      :items="environment.variables"
      hide-default-footer
      :page.sync="page"
      :items-per-page="10"
      @page-count="pageCount = $event"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Variáveis de Ambiente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small dark color="pink" class="mb-2 mr-3" @click="$refs['history'].open (environment, project, app, env)"><v-icon>manage_history</v-icon></v-btn>
          <v-btn small dark color="cyan" class="mb-2 mr-3" @click="$refs['inspect'].open (environment, project, app, env)"><v-icon>troubleshoot</v-icon></v-btn>
          <v-dialog v-model="dialogEdit" max-width="500px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>add</v-icon>
                Nova Variável
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form v-model="validate">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Nome"
                    outlined
                    class="mb-0"
                    :rules="rrules()"
                  />

                  <v-radio-group v-model="editedItem.type" @change="loadValue()" class="mt-0" :disabled="!validate">
                    <v-radio value="TEXT" label="Texto (plain text)" />
                    <v-radio value="SECRET" label="Chave (secret)" />
                    <v-radio value="PASSWORD" label="Senha (password)" />
                    <v-radio value="PORT" label="Porta" />
                    <v-radio value="VOLUME" label="Volume" />
                    <v-radio value="EMPTY" label="Vazio" />
                  </v-radio-group>

                  <v-text-field
                    v-if="['TEXT', 'SECRET', 'PASSWORD'].includes(editedItem.type)"
                    v-model="editedItem.value"
                    label="Valor"
                    outlined
                    :append-icon="editedItem.type === 'TEXT' ? 'article' : (editedItem.type === 'PASSWORD' ? 'lock' : 'vpn_key')"
                    :rules="rules"
                  />

                  <v-select
                    v-if="editedItem.type === 'VOLUME'"
                    label="Volume"
                    :items="volumes"
                    item-text="name"
                    item-value="name"
                    v-model="editedItem.value"
                    outlined
                    append-icon="inventory">
                    <template v-slot:item="{ item }">
                      <span style="font-family: monospace;" class="font-weight-bold">{{ item.name }}</span>
                    </template>
                  </v-select>

                  <template v-if="editedItem.type === 'PORT'">
                    <v-text-field
                      v-model="editedItem.value"
                      disabled
                      outlined
                      append-icon="meeting_room"
                    />
                  </template>
                </v-form>
              </v-card-text>

              <v-alert type="error" v-show="error.active" class="mx-4">{{ error.message }}</v-alert>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeEdit">Cancelar</v-btn>
                <v-btn color="success" @click="save" :disabled="!validate || !hasType() || !hasValue()" :loading="loading">{{ editedIndex < 0 ? 'Adicionar' : 'Alterar' }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
              <v-card-title class="text-h5">Tem certeza que deseja remover esta variável?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Não</v-btn>
                <v-btn color="blue darken-1" text @click="deleteConfirm">Sim</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          :disabled="[ 'SERVER', 'PORT', 'EMPTY' ].includes(item.type)"
        >
          edit
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          :disabled="item.type === 'SERVER'"
        >
          delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-alert color="warning" outlined icon="warning" class="mt-6">
          Adicione as variáveis de ambiente da aplicação!
        </v-alert>
      </template>
      <template v-slot:item.name="{ item }">
        <span style="font-family: monospace;" class="font-weight-bold">
          {{ item.name }}
        </span>
      </template>
      <template v-slot:item.type="{ item }">
        <v-chip dark :color="types[item.type].color" label small class="overline">
          <v-icon left small>{{ types[item.type].icon }}</v-icon>
          {{ item.type }}
        </v-chip>
      </template>
      <template v-slot:item.value="{ item }">
        <v-text-field :value="item.value" dense readonly hide-details hide-spin-buttons single-line flat filled rounded style="font-family: monospace;" v-show="![ 'EMPTY' ].includes(item.type)" />
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      />
    </div>
    <inspect-vars-wrapper ref="inspect" />
    <history-vars-wrapper ref="history" />
  </v-card>
</template>
<script>
import axios from 'axios'

import UtilHelper from '@/helpers/util'
import ErrorHelper from '@/helpers/error'

import InspectVarsWrapper from '@/components/DialogInspectVars.vue'
import HistoryVarsWrapper from '@/components/DialogHistoryVars.vue'

export default {
  props: {
    environment: {
      type: Object,
      require: true
    },
    project: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    app: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    env: {
      type: String,
      require: true,
      default: ''
    }
  },
  components: {
    InspectVarsWrapper,
    HistoryVarsWrapper
  },
  mixins: [
    UtilHelper,
    ErrorHelper
  ],
  data: () => ({
    dialogEdit: false,
    dialogDelete: false,
    loading: false,
    error: {
      active: false,
      message: ''
    },
    page: 1,
    pageCount: 0,
    headers: [
      { text: 'Variável', align: 'start', value: 'name', sortable: false },
      { text: 'Tipo', value: 'type', sortable: false },
      { text: 'Valor', value: 'value', sortable: false },
      { text: '', value: 'actions', sortable: false, align: 'end' }
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
      type: null,
      value: ''
    },
    defaultItem: {
      name: '',
      type: null,
      value: ''
    },
    validate: false,
    volumes: [],
    rules: [
      v => !/\s/.test(v) || 'Não utilize espaços em branco!'
    ],
    types: {
      SERVER: { color: 'brown lighten-3', icon: 'dns' },
      SECRET: { color: 'red lighten-3', icon: 'vpn_key' },
      TEXT: { color: 'indigo lighten-3', icon: 'article' },
      PORT: { color: 'teal lighten-3', icon: 'meeting_room' },
      PASSWORD: { color: 'purple lighten-3', icon: 'lock' },
      VOLUME: { color: 'blue-grey lighten-3', icon: 'inventory' },
      EMPTY: { color: 'amber', icon: 'comments_disabled' }
    }
  }),
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nova Variável' : 'Editar Variável'
    }
  },
  watch: {
    dialogEdit (val) {
      if (val) {
        this.error.active = false
        this.error.message = ''

        this.loading = false

        this.volumes = JSON.parse(JSON.stringify(this.environment.volumes))

        const prefix = this.app.repository.replace('/', '_') + '_' + this.env + '_'

        this.volumes.forEach(i => { i.name = prefix + i.name })
      } else this.closeEdit()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    editItem (item) {
      this.editedIndex = this.environment.variables.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },

    deleteItem (item) {
      this.editedIndex = this.environment.variables.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteConfirm () {
      this.environment.variables.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    closeEdit () {
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.environment.variables[this.editedIndex], this.editedItem)
      } else {
        this.environment.variables.push(this.editedItem)
      }
      this.closeEdit()
    },
    loadValue () {
      switch (this.editedItem.type) {
        case 'PASSWORD':
          this.editedItem.value = this.randomString(16)
          break

        case 'SECRET':
          this.editedItem.value = this.randomString(256)
          break

        case 'TEXT':
        case 'EMPTY':
          this.editedItem.value = ''
          break

        case 'PORT':
          this.editedItem.value = ''

          this.loadPort()

          break
      }
    },
    rrules () {
      return [
        v => !!v || 'Não pode ser vazio!',
        v => !v || /^[A-Z0-9_]+$/.test(v) || 'Utilize apenas letras maiúsculas, números e sublinhado!',
        v => v.length > 2 || 'Muito curto!',
        v => this.editedIndex >= 0 || this.environment.variables.filter(i => i.name === v).length === 0 || 'Este nome já está em uso!'
      ]
    },
    hasType () {
      return ['PASSWORD', 'SECRET', 'TEXT', 'PORT', 'VOLUME', 'EMPTY'].includes(this.editedItem.type)
    },
    hasValue () {
      switch (this.editedItem.type) {
        case 'PASSWORD':
        case 'SECRET':
        case 'TEXT':
          return !/\s/.test(this.editedItem.value)

        case 'PORT':
          return /^\d+$/.test(this.editedItem.value)

        case 'VOLUME':
          return this.volumes.filter(i => i.name === this.editedItem.value).length === 1

        case 'EMPTY':
          return true
      }

      return false
    },
    loadPort () {
      this.error.active = false
      this.error.message = ''

      if (!navigator.onLine) {
        this.error.message = 'É necessário uma conexão com a internet para obter uma porta! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error.active = true

        this.validate = false

        return
      }

      const err = error => {
        this.loading = false

        this.validate = false

        this.error.message = this.errorMessage(error)

        this.error.active = true
      }

      this.loading = true

      const headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        const s = { cluster: this.environment.cluster, variable: this.editedItem.name }

        axios.post(api + '/port/' + this.app.repository + '/' + this.env, s, { headers }).then(response => {
          this.editedItem.value = response.data.port.toString()

          this.loading = false
        }).catch(err)
      }).catch(err)
    }
  }
}
</script>
