<template>
  <v-snackbar v-model="hasUpdate" :multi-line="true" :timeout="60000">
    Nova versão disponível!
    <v-btn dark text @click="update()" color="info">
      Atualizar
    </v-btn>
    <v-btn dark icon @click="hasUpdate = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      hasUpdate: false,
      refreshing: false,
      registration: null
    }
  },
  created () {
    document.addEventListener('hasUpdate', (event) => {
      this.registration = event.detail
      this.hasUpdate = true
    }, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },
  methods: {
    update () {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }
}
</script>
