<template>
  <v-snackbar :timeout="timeout" :color="type" :multi-line="true" :vertical="false" v-model="snackbar">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click.native="snackbar = false">
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      snackbar: false,
      message: null,
      type: 'success',
      timeout: 8000
    }
  },
  methods: {
    open (message, type, timeout) {
      this.snackbar = true
      this.message = message
      this.type = type

      if (timeout) this.timeout = timeout
    }
  }
}
</script>
